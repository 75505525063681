export const MenuItems = [
    {
      title: 'About Us',
      path: '/about-us',
      cName: 'dropdown-link'
    },
    {
      title: 'Team',
      path: '/team',
      cName: 'dropdown-link'
    },
    {
      title: 'Constitution',
      path: '/constitution',
      cName: 'dropdown-link'
    }
  ];